import React, { useState, useEffect } from 'react';
import { supabase } from '../Components/supabaseClient';
import { Form, Button, Alert, Badge } from 'react-bootstrap';
import '../Style/SettingsPage.css';

function SettingsPage() {
  const [clients, setClients] = useState([]);
  const [clientName, setClientName] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      let { data, error } = await supabase.from('clients').select('*');
      if (error) throw error;
      setClients(data);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Error fetching clients');
    }
  };

  const handleAddClient = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    try {
      let { error } = await supabase.from('clients').insert([{ name: clientName }]);
      if (error) throw error;
      setSuccess('Client added successfully');
      fetchClients(); // Refresh the list after adding a new client
      setClientName(''); // Clear the input field
    } catch (error) {
      console.error('Error adding client:', error);
      setError('Error adding client');
    }
  };

  return (
    <div className='settingsPage'>
      <h1>Settings</h1>

      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}

      <Form onSubmit={handleAddClient}>
        <Form.Group className="mb-3">
          <Form.Label>Client Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter client name"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Add Client
        </Button>
      </Form>
      <br/>
      <br/>
      <h2>Clients</h2>
      <ul>
        {clients.map((client) => (
          <li key={client.id}>
             <Badge variant="secondary">{client.id}</Badge> {client.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SettingsPage;
