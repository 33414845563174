import '../Style/HomePage.css';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { supabase } from '../Components/supabaseClient';
import ChatPerHourBarChart from '../Components/ChatPerHourBarChart';

function HomePage({ activeClient }) {
  const [todaysChatAmount, setTodaysChatAmount] = useState(0);
  const [todaysHrefCount, setTodaysHrefCount] = useState(0);
  const [todaysErrorCount, setTodaysErrorCount] = useState(0);
  const [todaysSupportMailCount, setTodaysSupportMailCount] = useState(0);
  const [allHrefs, setAllHrefs] = useState([]);  // State to store all hrefs
  const [outsideWorkingHoursCount, setOutsideWorkingHoursCount] = useState(0);  // New state for outside working hours messages
  const [isLoading, setIsLoading] = useState(true);
  const [startDate] = useState(new Date().setHours(0, 0, 0, 0)); // default start date
  const [endDate] = useState(new Date().setHours(23, 59, 59, 999)); // default end date  

  useEffect(() => {
    const fetchFilteredData = async () => {
      var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      const start = new Date(startDate);
      const end = new Date(endDate);
      // Adjust the start and end dates to local ISO format in GMT+2
      const formattedStartDate = new Date(start.getTime() - tzoffset).toISOString();
      const formattedEndDate = new Date(end.getTime() - tzoffset).toISOString();

      try {
        const { data, error } = await supabase
          .from("chatLogs")
          .select('name, id, description, date, visited_pages')
          .eq('name', activeClient)
          .gte('date', formattedStartDate)
          .lte('date', formattedEndDate)
          .order('date', { ascending: false });

        if (error) throw error;
        if (data) {
          let outsideHours = 0;
          data.forEach(item => {
            const messageDate = new Date(item.date);
            const hours = messageDate.getHours();
            const minutes = messageDate.getMinutes();
            // Check if time is outside working hours (before 09:00 or after 16:30)
            if (hours < 9 || (hours === 16 && minutes > 30) || hours > 16) {
              outsideHours++;
            }
          });

          setOutsideWorkingHoursCount(outsideHours);
          setTodaysChatAmount(data.length);
          processHrefs(data);
          setTodaysErrorCount(data.filter(containsErrorKeyword).length)
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error.message);
        setIsLoading(false);
      }
    };

    fetchFilteredData();
  });


  function processHrefs(data) {
    const allLinks = [];
    const hrefCounts = data.reduce((acc, item) => {
      const hrefMatches = item.description.match(/href=["']([^"']*)["']/gi) || [];
      const mailtoCount = hrefMatches.filter(href => href.includes('mailto:')).length;
      const validHrefsCount = hrefMatches.filter(href => !href.includes('mailto:')).length;
      hrefMatches.forEach(href => {
        const urlMatch = href.match(/href=["']([^"']*)["']/i);
        if (urlMatch && urlMatch[1]) {
          let cleanUrl = urlMatch[1].split('?')[0];

          if (!allLinks.includes(cleanUrl)) {
            allLinks.push(cleanUrl);
          }
        }
      });
      acc.mailto += mailtoCount;
      acc.validHrefs += validHrefsCount;
      return acc;
    }, { mailto: 0, validHrefs: 0 });

    setTodaysHrefCount(hrefCounts.validHrefs);
    setTodaysSupportMailCount(hrefCounts.mailto);
    setAllHrefs(allLinks);
  }

  function containsErrorKeyword(data) {
    const description = data.description;
    return description.includes("🛠️") || description.includes("error") || description.includes(" 💫");
  }

  return (
    <div className='homePage'>
      <h2>Home</h2>
      <div className='card__container'>
        <Card className="pink" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Today's Chats</Card.Title>
            {isLoading ? (
              <div className="loader" /> // Show loading spinner when data is loading
            ) : (
              <Card.Text style={{ fontSize: "64px" }}>{todaysChatAmount}</Card.Text>
            )}
          </Card.Body>
        </Card>
        <Card className="yellow" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Today's Product Recommendation amount</Card.Title>
            {isLoading ? (
              <div className="loader" /> // Show loading spinner when data is loading
            ) : (
              <Card.Text style={{ fontSize: "64px" }}>{todaysHrefCount}</Card.Text>
            )}
          </Card.Body>
        </Card>
        <Card className="green" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Today's Support Mail amount</Card.Title>
            {isLoading ? (
              <div className="loader" /> // Show loading spinner when data is loading
            ) : (
              <Card.Text style={{ fontSize: "64px" }}>{todaysSupportMailCount}</Card.Text>
            )}
          </Card.Body>
        </Card>
      </div>
      <div className='card__container'>
        <Card className="beige" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Today's All Links</Card.Title>
            {isLoading ? (
              <div className="loader" /> // Show loading spinner when data is loading
            ) : (
              <Card.Text style={{ textAlign: "left" }}>
                {allHrefs.map((href, index) => (
                  <span className="today-links" key={index}><a target="_blank" rel="noreferrer" href={`${href}`}>{href.replace(/^(?:https?:\/\/)?(?:www\.)?[^]+\.(com|se|org|net|co\.uk)\//i, '$1/')}</a></span>
                ))}
              </Card.Text>
            )}
          </Card.Body>
        </Card>
        <Card className="green" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Messages Outside Working Hours</Card.Title>
            {isLoading ? (
              <div className="loader" /> // Show loading spinner when data is loading
            ) : (
              <Card.Text style={{ fontSize: "64px" }}>{outsideWorkingHoursCount}</Card.Text>
            )}
          </Card.Body>
        </Card>
        <Card className="pink" style={{ marginBottom: "2rem" }}>
          <Card.Body>
            <Card.Title>Todays Error Count</Card.Title>
            {isLoading ? (
              <div className="loader" /> // Show loading spinner when data is loading
            ) : (
              <Card.Text style={{ fontSize: "64px" }}>{todaysErrorCount}</Card.Text>
            )}
          </Card.Body>
        </Card>
      </div>
      <div className='chart__container'>
        <h3>Chat Activity Over the Last Week</h3>
        {isLoading ? (
          <div className="loader" /> // Show loading spinner when data is loading
        ) : (
          <ChatPerHourBarChart activeClient={activeClient} />
        )}
      </div>
    </div>
  );
}

export default HomePage;
